export const ADD_RESPONSE_TITLE = "Add your response";
export const EDIT_RESPONSE_TITLE = "Edit response";

export const RESPONSE = "response";
export const RESPONSES = "responses";

export const NO_RESPONSES = "No responses available for this query.";
export const DELETE_RESPONSE_CONFIRM =
  "Are you sure you want to delete this response?";
export const ANSWERED_BY = "Answered by";

export const ANSWER_VERIFIED = "Completed";
export const ANSWER_PENDING_VERIFICATION = "In-Process";
