import React, { useState, useEffect, useRef } from "react";

import TankEditorTool from "../../TankEditor/Editor";
import Modal from "../Modal/Modal";

import { useGetCollections } from "../../../Hooks/getCollections";
import { collectionsList } from "../../../api/api";
import { convertToHTMLString } from "../../TankEditor/convertToHtmlString";

import {
  SAVE,
  ENTER_QUERY_TITLE,
  SELECT_COLLECTION,
} from "../../../constants/Question";

import { CANCEL } from "../../../constants/ButtonLabel";

import InputField from "../../InputField/InputField";
import SelectDropdown from "../../SelectDropdown/SelectDropdown";

import "./EditQuestionModal.scss";

const EditQuestionModal = (props) => {
  const {
    open,
    close,
    quesTitle,
    quesDesc,
    collecID,
    updateQues,
    collectionTitle,
    userClientID,
    handleQuestionSaveDraft,
    userTankID,
  } = props;

  const [collectionName, setCollectionName] = useState("");
  const [collecId, setCollecId] = useState("");
  const [editorContent, setEditorContent] = useState("");
  const [isQuestionContentAdded, setIsQuestionContentAdded] = useState(false);
  const [questionTitle, setQuestionTitle] = useState("");
  const [isDisabled, setIsDisabled] = useState(false);
  const [editorTool, setEditorTool] = useState();
  const [collections] = useGetCollections(
    `${collectionsList}/${userClientID}/${userTankID}`
  );
  const textareaRef = useRef(null);

  const collectionNames = [];
  if (collections && collections.data) {
    collections.data.forEach((collec) => {
      collectionNames.push({
        id: collec.collectionId,
        name: collec.collectionName,
      });
    });
  }

  const divStyle = {
    display: open ? "flex" : "none",
  };

  useEffect(() => {
    setQuestionTitle(quesTitle);
    setCollecId(collecID);
  }, [quesTitle, collecID]);

  const handleEditorChange = (content, editor) => {
    setEditorContent(content);

    if (content.trim() === "") {
      setIsQuestionContentAdded(false);
    } else {
      setIsQuestionContentAdded(true);
    }
  };

  const handleCollectionSelectChange = (event, child) => {
    setCollectionName(event.target.value);
    setCollecId(child.key.replace(".$", ""));
  };

  const focusHandle = (val) => {
    setQuestionTitle(val);
  };

  const handleQuestionTitleChange = (val) => {
    setQuestionTitle(val);
  };

  const handleEditorContent = (isDraft = false) => {
    editorTool
      ?.save()
      .then((outputData) => {
        const parsedData = convertToHTMLString(outputData?.blocks);
        if (!isDraft) {
          updateQues(
            questionTitle,
            { quesDesc: parsedData, savedData: outputData },
            collecId
          );
        } else {
          handleQuestionSaveDraft(
            questionTitle,
            { quesDesc: parsedData, savedData: outputData },
            collecId
          );
        }
      })
      .catch((error) => {
        console.log("Saving failed: ", error);
      });
  };

  const adjustHeight = () => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = "54px";
      if (textarea.scrollHeight > 54) {
        textarea.style.height = `${textarea.scrollHeight}px`;
      }
    }
  };

  useEffect(() => {
    adjustHeight(); // Adjust height on initial render
  }, [questionTitle]);

  return (
    <div className="edit-ques-modal">
      <Modal
        modalDisplay={divStyle}
        close={close}
        cancelText={CANCEL}
        provisionType={SAVE}
        // isDisabled={isDisabled}
        handleProvisioning={() => handleEditorContent(false)}
        isDraftBtn={true}
        handleSaveAsDraft={() => handleEditorContent(true)}
        isFullPageEditor={true}
      >
        <div className="container-wrapper">
          <InputField
            inputValue={questionTitle}
            type={"text"}
            inputTag={"textarea"}
            handleOnChange={handleQuestionTitleChange}
            placeholderText={ENTER_QUERY_TITLE}
            handleOnFocus={focusHandle}
            textareaRef={textareaRef}
            style={{
              overflow: "hidden",
              height: "auto",
            }}
          />
          <SelectDropdown
            labelText={SELECT_COLLECTION}
            value={collectionName === "" ? collectionTitle : collectionName}
            handleOnChange={handleCollectionSelectChange}
            options={collectionNames}
          />
        </div>
        <TankEditorTool
          // editorContentChange={handleEditorChange}
          setEditor={setEditorTool}
          editorTool={editorTool}
          prefillData={quesDesc}
        />
      </Modal>
    </div>
  );
};

export default EditQuestionModal;
