import React from "react";

import TankEditorTool from "../../TankEditor/Editor";
import Modal from "../Modal/Modal";

import { CANCEL, SAVE } from "../../../constants/ButtonLabel";
import { convertToHTMLString } from "../../TankEditor/convertToHtmlString";

const EditAnswerModal = (props) => {
  const {
    open,
    close,
    setAnswer,
    ansID,
    queID,
    updatedAns,
    handleAnswerSaveDraft,
    isDraftAnswer = false,
    setEditorTool,
    editorTool,
    quesTitle,
    collectionTitle,
  } = props;

  const divStyle = {
    display: open ? "flex" : "none",
  };

  const handleAnswerAdd = () => {
    editorTool?.save().then((outputData) => {
      const parsedData = convertToHTMLString(outputData?.blocks);
      if (isDraftAnswer) {
        updatedAns({ answerDesc: parsedData, savedData: outputData });
      } else {
        updatedAns(queID, ansID, {
          answerDesc: parsedData,
          savedData: outputData,
        });
      }
    });
  };

  const handleAnswerSaveAsDraft = () => {
    editorTool?.save().then((outputData) => {
      const parsedData = convertToHTMLString(outputData?.blocks);
      handleAnswerSaveDraft({
        answerDesc: parsedData,
        savedData: outputData,
      });
    });
  };

  return (
    <Modal
      close={close}
      modalDisplay={divStyle}
      cancelText={CANCEL}
      provisionType={SAVE}
      handleProvisioning={handleAnswerAdd}
      handleSaveAsDraft={handleAnswerSaveAsDraft}
      isDraftBtn={true}
      isFullPageEditor={true}
    >
      <div className="container-wrapper">
        <div className="query-details">
          <h3 className="query-title">{quesTitle}</h3>
          <p className="query-collection">
            <span className="collection-title">COLLECTION:</span>
            <span className="collection-name">{collectionTitle}</span>
          </p>
        </div>
      </div>
      <TankEditorTool
        setEditor={setEditorTool}
        editorTool={editorTool}
        prefillData={setAnswer}
        placeholder="Edit your response..."
      />
    </Modal>
  );
};

export default EditAnswerModal;
