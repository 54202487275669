import React from "react";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import "./Toast.scss";

const Toast = (props) => {
  const { isToastOpen, message, closeToast, type = "success" } = props;

  return (
    <div className="Toast-Notification">
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={isToastOpen}
        autoHideDuration={3000}
        onClose={closeToast}
      >
        <MuiAlert
          // onClose={closeToast}
          severity={type}
          elevation={6}
          variant="filled"
          className="toast-message"
        >
          {message}
        </MuiAlert>
      </Snackbar>
    </div>
  );
};

export default Toast;
