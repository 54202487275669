import React, { useState, useEffect } from "react";
import { ADD_RESPONSE_TITLE } from "../../../constants/Answer";
import { CANCEL, ADD } from "../../../constants/ButtonLabel";

// import TankEditor from "../../TankEditor/TankEditor";
import TankEditorTool from "../../TankEditor/Editor";
import Modal from "../Modal/Modal";
import { convertToHTMLString } from "../../TankEditor/convertToHtmlString";

import "./AddAnswerModal.scss";

const AddAnswerModal = (props) => {
  const [editorContent, setEditorContent] = useState({});
  const [editorTool, setEditorTool] = useState();

  const {
    open,
    currentTeamData,
    close,
    addAns,
    handleAnswerSaveDraft,
    quesTitle,
    collectionTitle,
  } = props;

  const divStyle = {
    display: open ? "flex" : "none",
  };

  useEffect(() => {
    if (!open) {
      setEditorContent("");
    }
  }, [open]);

  const handleEditorContent = (isDraftAnswer = false) => {
    editorTool
      ?.save()
      .then((outputData) => {
        const parsedData = convertToHTMLString(outputData?.blocks);
        if (!isDraftAnswer) {
          addAns(
            { savedData: outputData, answerDesc: parsedData },
            currentTeamData?.name
          );
        } else {
          handleAnswerSaveDraft(
            { savedData: outputData, answerDesc: parsedData },
            "add"
          );
        }
      })
      .catch((error) => {
        console.log("Saving failed: ", error);
      });
  };

  return (
    <Modal
      modalDisplay={divStyle}
      close={close}
      // isDisabled={false}
      handleProvisioning={() => handleEditorContent(false)}
      cancelText={CANCEL}
      provisionType={ADD}
      handleSaveAsDraft={() => handleEditorContent(true)}
      isDraftBtn={true}
      // isDraftBtnDisabled={false}
      isFullPageEditor={true}
    >
      <div className="container-wrapper">
        <div className="query-details">
          <h3 className="query-title">{quesTitle}</h3>
          <p className="query-collection">
            <span className="collection-title">COLLECTION:</span>
            <span className="collection-name">{collectionTitle}</span>
          </p>
        </div>
      </div>
      <TankEditorTool
        // editorContentChange={handleEditorChange}
        setEditor={setEditorTool}
        editorTool={editorTool}
        placeholder={`${ADD_RESPONSE_TITLE}...`}
      />
    </Modal>
  );
};

export default AddAnswerModal;
