import React, { useState, useEffect, useRef } from "react";
import axios from "axios";

import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import { makeStyles } from "@material-ui/core/styles";

import TankEditorTool from "../../TankEditor/Editor";
import Modal from "../Modal/Modal";

import { getCollectionDetailsByID } from "../../../api/api";
import { convertToHTMLString } from "../../TankEditor/convertToHtmlString";
import {
  ENTER_QUERY_TITLE,
  SELECT_COLLECTION,
} from "../../../constants/Question";
import { CANCEL, CREATE } from "../../../constants/ButtonLabel";

import InputField from "../../InputField/InputField";
import SearchDropdown from "../../SearchDropdown/SearchDropdown";

import "./NewQuestionModal.scss";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const NewQuestionModal = (props) => {
  const {
    open,
    close,
    addQues,
    handleQuestionSaveDraft,
    colId,
    teamMembers,
    teamMemberNameKey,
    handleOptionSelection,
    collectionData,
    queryLengthError = false,
  } = props;
  const classes = useStyles();
  const [editorContent, setEditorContent] = useState("");
  const [questionTitle, setQuestionTitle] = useState("");
  const [collectionName, setCollectionName] = useState("");
  const [collecId, setCollecId] = useState("");
  const [editorTool, setEditorTool] = useState();
  const textareaRef = useRef(null);

  const divStyle = {
    display: open ? "flex" : "none",
  };

  const getCollectionDetails = (id) => {
    axios.get(`${getCollectionDetailsByID}${id}`).then((res) => {
      const resData =
        res &&
        res.data &&
        res.data.data &&
        res.data.data[0] &&
        res.data.data[0].collectionName;
      setCollectionName(resData);
    });
  };

  const adjustHeight = () => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = "54px";
      if (textarea.scrollHeight > 54) {
        textarea.style.height = `${textarea.scrollHeight}px`;
      }
    }
  };

  useEffect(() => {
    if (open) {
      setEditorContent("");
      setQuestionTitle("");
    }
  }, [open]);

  useEffect(() => {
    adjustHeight();
  }, [questionTitle]);

  useEffect(() => {
    if (colId) {
      setCollecId(colId);
      getCollectionDetails(colId);
    }
  }, [colId]);

  const handleCollectionSelectChange = (event, child) => {
    setCollectionName(event.target.value);
    setCollecId(child.key.replace(".$", ""));
  };

  const handleEditorContent = (isDraft = false) => {
    editorTool
      ?.save()
      .then((outputData) => {
        const parsedData = convertToHTMLString(outputData?.blocks);
        if (!isDraft) {
          addQues(collecId, questionTitle, {
            questionDesc: parsedData,
            savedData: outputData,
          });
        } else {
          handleQuestionSaveDraft(
            questionTitle,
            { questionDesc: parsedData, savedData: outputData },
            collecId
          );
        }
      })
      .catch((error) => {
        console.log("Saving failed: ", error);
      });
  };

  return (
    <div className="new-ques-modal">
      <Modal
        // isDisabled={isSubmitDisabled}
        cancelText={CANCEL}
        provisionType={CREATE}
        handleProvisioning={() => handleEditorContent(false)}
        modalDisplay={divStyle}
        close={close}
        isDraftBtn={true}
        // isDraftBtnDisabled={isDraftBtnDisabled}
        handleSaveAsDraft={() => handleEditorContent(true)}
        queryLengthError={queryLengthError}
        isFullPageEditor={true}
      >
        <div className="container-wrapper">
          <InputField
            inputValue={questionTitle}
            type={"text"}
            inputTag={"textarea"}
            handleOnChange={setQuestionTitle}
            placeholderText={ENTER_QUERY_TITLE}
            textareaRef={textareaRef}
            style={{
              overflow: "hidden",
              height: "auto",
            }}
          />
          <div className="collection-and-guru">
            <div className="collection-select">
              <InputField labelText={SELECT_COLLECTION} />
              {collectionData && collectionData.length ? (
                <FormControl className={`${classes.formControl} dropdown-comp`}>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={collectionName}
                    onChange={handleCollectionSelectChange}
                    className="selected-option"
                    defaultValue={"no colection"}
                  >
                    {collectionData &&
                      collectionData.map((col) => (
                        <MenuItem
                          key={col.collectionId}
                          className="dropdown-menu-item"
                          value={col.collectionName}
                        >
                          {col.collectionName}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              ) : (
                <div className="no-collec-found">
                  <InputField
                    type={"text"}
                    inputTag={"input"}
                    placeholderText={"No collection found"}
                  />
                </div>
              )}
            </div>
            <SearchDropdown
              label={"Add mentions"}
              options={teamMembers}
              teamMemberNameKey={teamMemberNameKey}
              handleOptionSelection={handleOptionSelection}
            />
          </div>
        </div>
        <TankEditorTool
          // editorContentChange={handleEditorChange}
          setEditor={setEditorTool}
          editorTool={editorTool}
        />
      </Modal>
    </div>
  );
};

export default NewQuestionModal;
