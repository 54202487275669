import React, { Component } from "react";

import { getFollowedQueries, followQuery, unfollowQuery } from "../../api/api";
import axios from "axios";

import Question from "../../components/Question/Question";

import TableShimmer from "../../components/Shimmer/TableShimmer";

import { RESPONSE, RESPONSES } from "../../constants/Answer";
import { ORIGIN_TANK } from "../../constants";

import "./InterestsPage.scss";
class InterestsPage extends Component {
  state = {
    questionsData: [],
    loading: true,
    quesTitle: "",
    quesDesc: "",
    collecId: "",
    quesCollectionId: "",
    collectionTitle: "",
  };

  componentDidMount() {
    const { userTankID, userClientID } = this.props;
    this.fetchFollowedQueries(userTankID, userClientID);
  }

  fetchFollowedQueries = async (tankID, spaceID) => {
    const LOADING_DELAY = 600;
    const followBody = {
      tankUserID: tankID,
      spaceID: spaceID,
    };
    const result = await axios.post(getFollowedQueries, followBody);

    const interestsData = result.data && result.data.data;

    if (interestsData) {
      this.setState({ questionsData: interestsData });
    }

    setTimeout(() => {
      this.setState({ loading: false });
    }, LOADING_DELAY);
  };

  handleFollowQuestion = (quesId) => {
    const { userTankID, userClientID } = this.props;
    const body = {
      tankUserID: userTankID,
      spaceID: userClientID,
      quesId: quesId,
    };
    axios.post(followQuery, body);
  };

  handleUnfollowQuestion = (quesId) => {
    const { userTankID, userClientID } = this.props;
    const body = {
      tankUserID: userTankID,
      spaceID: userClientID,
      quesId: quesId,
    };
    axios
      .post(unfollowQuery, body)
      .then(() => this.fetchFollowedQueries(userTankID, userClientID));
  };

  render() {
    const { userTankID, userClientID, currentTeamData, userRole, creatorId } =
      this.props;
    const questionEntries = [];
    this.state.questionsData.length > 0 &&
      this.state.questionsData.forEach((ques) => {
        questionEntries.push({
          quesId: ques.quesId,
          quesTitle: ques.quesTitle,
          userId: ques.userId,
          userName: ques.userName,
          answers: ques.answers.length,
          collecId: ques.collectionId,
          origin: ques.origin,
          createdAt: ques.createdAt,
          followed: true,
          quesCreatorDp:
            ques?.origin === ORIGIN_TANK
              ? ques?.creatorDetails?.[0]?.dp
              : ques?.slackCreatorDetails?.[0]?.avatar,
        });
      });

    return (
      <>
        <div className="interests-page page-top">
          <div className="question-dash page-loader-pos">
            {this.state.loading ? (
              <TableShimmer variant="rect" width="100%" />
            ) : questionEntries && questionEntries.length ? (
              <div className="table-card">
                <table>
                  <thead>
                    <tr>
                      <th>Title</th>
                      <th>Responses</th>
                      <th>Origin</th>
                      <th>Collection</th>
                      <th>Creator</th>
                      <th>Actions</th>
                      <th>Created on</th>
                    </tr>
                  </thead>
                  <tbody>
                    {questionEntries.map((entry) => {
                      const {
                        quesId,
                        quesTitle,
                        userName,
                        answers,
                        collecId,
                        origin,
                        createdAt,
                        followed,
                        quesCreatorDp,
                      } = entry;
                      let answerText =
                        answers === 0 || answers > 1 ? RESPONSES : RESPONSE;

                      return (
                        <Question
                          currentTeamData={currentTeamData}
                          userRole={userRole}
                          creatorId={creatorId}
                          key={quesId}
                          quesId={quesId}
                          collecId={collecId}
                          quesTitle={quesTitle}
                          userName={userName}
                          answers={answers}
                          answerText={answerText}
                          handleAnswerModalOpen={() => {}}
                          handleQuestionDelete={() => {}}
                          renderEditQuestionModal={() => {}}
                          isFollowed={followed}
                          userTankID={userTankID}
                          userClientID={userClientID}
                          toRenderData={true}
                          fetchFollowedQueries={this.fetchFollowedQueries}
                          origin={origin}
                          createdAt={createdAt}
                          handleFollowQuestion={this.handleFollowQuestion}
                          handleUnfollowQuestion={this.handleUnfollowQuestion}
                          toRenderCollectionName={true}
                          openMoveQuestionModal={() => {}}
                          hasModifyOptions={false}
                          userDP={quesCreatorDp}
                        />
                      );
                    })}
                  </tbody>
                </table>
              </div>
            ) : (
              <div className="no-interests-added">
                <div className="no-interests-asset">
                  <img
                    src={require("../../assets/images/no-interests.png")}
                    alt="no interests"
                  />
                </div>
                <h1>No interests added</h1>
              </div>
            )}
          </div>
        </div>
      </>
    );
  }
}

export default InterestsPage;
