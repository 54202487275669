import React from "react";
import { Link } from "react-router-dom";

import { ROUTES } from "../../constants";
import {
  CONTACT_US_PAGE,
  PRIVACY_POLICY_PAGE,
  INTEGRATIONS_PAGE,
  TERMS_AND_CONDITIONS_PAGE,
} from "../../constants/PageRoutes";
import Grid from "@material-ui/core/Grid";
import Logo from "../Logo/Logo";
import {
  HOME_PAGE,
  PRICING_PAGE,
  FEATURES_PAGE,
} from "../../constants/PageRoutes";
import { TANK_LOGO_BLUE } from "../../constants";

import LinkedInIcon from "@material-ui/icons/LinkedIn";
import YouTubeIcon from "@material-ui/icons/YouTube";

import "./Footer.scss";

const Footer = (props) => {
  if (ROUTES.includes(props.location.pathname.split("/")[1])) return null;

  return (
    <section className="footer-section">
      <div className="footer screen-max-width">
        <Grid container spacing={6} className="footer-container">
          <Grid item xs={12} md={6} lg={6}>
            <a href={HOME_PAGE}>
              <Logo imageUrl={TANK_LOGO_BLUE} />
            </a>
            <div className="copyright-block">
              <p className="copyright-text">
                &copy; Copyright {new Date().getFullYear()}. All rights
                reserved.
              </p>
              <div className="social-links-block">
                <a
                  href="https://twitter.com/OrgTank"
                  target="_blank"
                  rel="noreferrer"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-twitter-x"
                    viewBox="0 0 16 16"
                  >
                    <path d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865z" />
                  </svg>
                </a>
                <a
                  href="https://www.linkedin.com/company/trytank/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <LinkedInIcon fontSize="medium" />
                </a>
                <a
                  href="https://www.youtube.com/@tryTankOrg"
                  target="_blank"
                  rel="noreferrer"
                >
                  <YouTubeIcon fontSize="medium" />
                </a>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={3} lg={3} className="footer-links-container">
            <p className="footer-title">PRODUCT</p>
            <ul className="footer-links">
              <li>
                <Link to={PRICING_PAGE}>Pricing</Link>
              </li>
              <li>
                <Link to={FEATURES_PAGE}>Features</Link>
              </li>
              <li>
                <Link to={INTEGRATIONS_PAGE}>Slack Integration</Link>
              </li>
            </ul>
          </Grid>
          <Grid item xs={12} md={3} lg={3} className="footer-links-container">
            <p className="footer-title">COMPANY</p>
            <ul className="footer-links">
              <li>
                <Link to={TERMS_AND_CONDITIONS_PAGE}>
                  {"Terms & conditions"}
                </Link>
              </li>
              <li>
                <Link to={PRIVACY_POLICY_PAGE}>Privacy policy</Link>
              </li>
              <li>
                <Link to={CONTACT_US_PAGE}>Contact Us</Link>
              </li>
            </ul>
          </Grid>
        </Grid>
      </div>
    </section>
  );
};

export default Footer;
