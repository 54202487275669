import React from "react";

import ButtonGroup from "./ButtonGroup/ButtonGroup";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";

import "./Modal.scss";

const Modal = (props) => {
  const {
    modalDisplay,
    cancelText,
    provisionType,
    close,
    isDisabled,
    handleProvisioning,
    children,
    isLoading,
    isDraftBtn,
    isDraftBtnDisabled,
    handleSaveAsDraft,
    moveToTrash,
    isBtnDisabled = false,
    formError = false,
    formErrMsg = "",
    queryLengthError = false,
    showCloseIcon = true,
    isFullPageEditor = false,
  } = props;

  const renderButtonGroup = () => (
    <ButtonGroup
      close={close}
      isDisabled={isDisabled}
      handleProvisioning={handleProvisioning}
      cancelText={cancelText}
      provisionType={provisionType}
      isDraftBtn={isDraftBtn}
      moveToTrash={moveToTrash !== "" ? moveToTrash : null}
      isDraftBtnDisabled={isDraftBtnDisabled}
      handleSaveAsDraft={handleSaveAsDraft}
      isBtnDisabled={isBtnDisabled}
    />
  );

  return (
    <div
      className={`answer-modal modal-comp ${
        isFullPageEditor ? "full-page-editor" : ""
      }`}
      style={modalDisplay}
    >
      <div className={`modal-content`} onClick={(e) => e.stopPropagation()}>
        <div className="modal-inner-wrapper">
          <div className="container-wrapper">
            {!isLoading && isFullPageEditor ? renderButtonGroup() : null}
          </div>
          {children}
          {showCloseIcon && (
            <CloseOutlinedIcon
              onClick={close}
              className="close-modal-icon"
              fontSize="small"
            />
          )}
        </div>
        {formError && <p className="form-err-msg">{formErrMsg}</p>}
        {queryLengthError && (
          <p className="form-err-msg">
            {"Query length more than 150 characters"}
          </p>
        )}
        {!isLoading && !isFullPageEditor ? renderButtonGroup() : null}
      </div>
    </div>
  );
};

export default Modal;
