import React from "react";
import Grid from "@material-ui/core/Grid";

import "./styles.scss";

const FeaturesPage = () => {
  return (
    <div className="features-page page-top">
      <div className="inner-section">
        <img
          className="beige-shape shapes-img"
          src={require("../../assets/images/beige-shape.png")}
          alt="intro"
        />
        <div className="features-sections">
          <div className="page-heading screen-max-width">
            <p className="heading">Simplify how your team handles knowledge</p>
            <p className="desc">
              Tank’s simple workflows let your team easily create, organize, and
              expand their knowledge base with ease.
            </p>
          </div>
          <div className="features-container">
            <Grid
              container
              spacing={6}
              className="queries-and-answers screen-max-width features-info"
            >
              <Grid item xs={12} sm={6} className="pd-right">
                <img
                  className="feature-img"
                  src={require("../../assets/images/file-ticket.png")}
                  alt="file ticket"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <p className="section-heading">
                  <div className="section-title-icon left">
                    <img
                      className="section-icon"
                      src={require("../../assets/images/collab-qna.png")}
                      alt="Collaborative Queries and Answers"
                    />
                  </div>
                  Collaborative Queries and Answers
                </p>
                <p className="section-desc">
                  Empower your team to share knowledge seamlessly. Create
                  queries for specific challenges or gaps, enable team members
                  to contribute detailed answers, and transform your knowledge
                  base into a growing resource that evolves with your team’s
                  expertise.
                </p>
              </Grid>
            </Grid>
          </div>
          <div className="features-container">
            <Grid
              container
              spacing={6}
              className="team-process screen-max-width features-info"
            >
              <Grid item xs={12} sm={6}>
                <p className="section-heading">
                  <div className="section-title-icon right">
                    <img
                      className="section-icon"
                      src={require("../../assets/images/org-knowledge.png")}
                      alt="Organized Knowledge with Collections"
                    />
                  </div>
                  Organized Knowledge with Collections
                </p>
                <p className="section-desc">
                  Keep your queries and answers organized with Collections,
                  which act as custom categories for specific topics. Whether
                  it’s product features, troubleshooting guides, or team
                  processes, collections ensure quick access to the right
                  information.
                </p>
              </Grid>
              <Grid item xs={12} sm={6} className="pd-left">
                <img
                  className="feature-img"
                  src={require("../../assets/images/team-process.png")}
                  alt="team process"
                />
              </Grid>
            </Grid>
          </div>
          <div className="features-container">
            <img
              className="cyan-shape shapes-img"
              src={require("../../assets/images/cyan-bg.png")}
              alt="intro"
            />
            <Grid
              container
              spacing={6}
              className="expert-approved screen-max-width features-info"
            >
              <Grid item xs={12} sm={6} className="pd-right">
                <img
                  className="feature-img"
                  src={require("../../assets/images/expert-approved.png")}
                  alt="expert approved"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <p className="section-heading">
                  <div className="section-title-icon exp left">
                    <img
                      className="section-icon"
                      src={require("../../assets/images/expert-content-tick.png")}
                      alt="Expert-Approved Content"
                    />
                  </div>
                  Expert-Approved Content
                </p>
                <p className="section-desc">
                  Keep your knowledge base trustworthy with dedicated gurus for
                  each collection. Gurus review, validate, and verify answers to
                  maintain the highest level of accuracy and reliability.
                </p>
              </Grid>
            </Grid>
          </div>
          <div className="features-container">
            <img
              className="pink-shape shapes-img"
              src={require("../../assets/images/ping-bg.png")}
              alt="intro"
            />
            <Grid
              container
              spacing={6}
              className="team-collab screen-max-width features-info"
            >
              <Grid item xs={12} sm={6}>
                <p className="section-heading">
                  <div className="section-title-icon mention right">
                    <img
                      className="section-icon"
                      src={require("../../assets/images/mention-icon.png")}
                      alt="Effortless Team Collaboration with Mentions"
                    />
                  </div>
                  Effortless Team Collaboration with Mentions
                </p>
                <p className="section-desc">
                  Involve the right people instantly. Mention teammates while
                  creating a query to alert experts and get faster, more precise
                  answers tailored to your needs.
                </p>
              </Grid>
              <Grid item xs={12} sm={6} className="pd-left">
                <img
                  className="feature-img"
                  src={require("../../assets/images/enter-query.png")}
                  alt="team collab"
                />
              </Grid>
            </Grid>
          </div>
          <div className="features-container">
            <Grid
              container
              spacing={6}
              className="team-onboarding screen-max-width features-info"
            >
              <Grid item xs={12} sm={6} className="pd-right">
                <img
                  className="feature-img"
                  src={require("../../assets/images/invite.png")}
                  alt="team onboarding"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <p className="section-heading">
                  <div className="section-title-icon onboarding left">
                    <img
                      className="section-icon"
                      src={require("../../assets/images/team-onboard-icon.png")}
                      alt="Simplified Team Onboarding"
                    />
                  </div>
                  Simplified Team Onboarding
                </p>
                <p className="section-desc">
                  Bring your team together effortlessly. Use the “Invite Team
                  Member” feature to send quick email invites, helping
                  colleagues join Spaces and start contributing right away.
                </p>
              </Grid>
            </Grid>
          </div>
          <div className="slack-features">
            <img
              className="section-bg-img"
              src={require("../../assets/images/white-wave.png")}
              alt="white wave"
            />
            <div className="screen-max-width">
              <div className="slack-wrapper">
                <img
                  src={require("../../assets/images/tank-plus-slack.png")}
                  alt="tank with slack"
                />
              </div>
              <div className="slack-features-container">
                <p className="section-heading">
                  Slack Integration for Instant Productivity
                </p>
                <p className="section-desc">
                  Supercharge your knowledge-sharing workflow by integrating
                  Tank with Slack.
                </p>
                <p className="section-desc">
                  Create, search, and manage queries effortlessly, all within
                  your team’s favourite communication tool.
                </p>
              </div>
              <div className="slack-shortcuts">
                <Grid container spacing={6} className="shortcuts-info">
                  <Grid item xs={12} sm={6} className="shortcuts-grid">
                    <div className="shortcuts-info-container global-shortcut">
                      <p className="section-heading">Global Shortcut:</p>
                      <p className="section-desc">
                        Type <span>/post a query</span> anywhere in Slack to
                        start a new query effortlesly.
                      </p>
                    </div>
                    <div className="shortcuts-info-container">
                      <p className="section-heading">
                        Transform Messages into Queries:
                      </p>
                      <p className="section-desc">
                        Tank’s message shortcut allows you to start creating a
                        query instantly.
                      </p>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6} className="pd-left">
                    <img
                      className="feature-img"
                      src={require("../../assets/images/slack-post-query.png")}
                      alt="post query"
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={6} className="shortcuts-info">
                  <Grid item xs={12} md={6} className="mobile-only">
                    <div className="shortcuts-info-container">
                      <p className="section-heading cmds">Use commands like:</p>
                      <p className="section-desc">
                        <span>/tank-search[search-terms]</span> - Quickly search
                        your Space for content matching your search terms. Get a
                        list of queries that fit your criteria.
                      </p>
                      <p className="section-desc">
                        <span>/tank-help</span> - Access a complete list of
                        available commands and helpful tips.
                      </p>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6} className="pd-right">
                    <img
                      className="feature-img"
                      src={require("../../assets/images/slack-search.png")}
                      alt="slack search"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} className="desktop-only">
                    <div className="shortcuts-info-container">
                      <p className="section-heading">Use commands like:</p>
                      <p className="section-desc">
                        <span>/tank-search[search-terms]</span> - Quickly search
                        your Space for content matching your search terms. Get a
                        list of queries that fit your criteria.
                      </p>
                      <p className="section-desc">
                        <span>/tank-help</span> - Access a complete list of
                        available commands and helpful tips.
                      </p>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeaturesPage;
